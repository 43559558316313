/** @format */

import { Link } from "gatsby";
import React, { useEffect, useState, useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import { AppContext } from "../../utils/ContextWrapper";
import imgDesayuno from "../../images/desayuno.png";
function Recetas() {
  const { store, actions } = useContext(AppContext);

  const { tipoRecetaSeleccionada } = store;
  const { setTipoRecetaSeleccionada } = actions;

  const [recetas, setRecetas] = useState([]);

  const [tiposRecetas, setTiposRecetas] = useState([
    { descripcion: "Selecciona un tipo de receta", idTipoReceta: 0 },
    { descripcion: "Desayunos", idTipoReceta: 1 },
    { descripcion: "Almuerzos", idTipoReceta: 2 },
    { descripcion: "Snacks", idTipoReceta: 3 },
    { descripcion: "Cenas", idTipoReceta: 4 },
  ]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handeChangeTipoReceta = (ev) => {
    setTipoRecetaSeleccionada(tiposRecetas[ev]);
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    let urlEnvio = "";
    if (tipoRecetaSeleccionada.idTipoReceta !== 0) urlEnvio = `/${tipoRecetaSeleccionada.idTipoReceta}`;
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/recetas${urlEnvio}`)
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setRecetas(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });

    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/tiposRecetas`)
      .then((result) => result.json())
      .then((result) => {
        setTiposRecetas(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [tipoRecetaSeleccionada]);

  return (
    <>
      <div>
        <DropdownButton
          id="dropdown-basic-button"
          onSelect={handeChangeTipoReceta}
          variant="warning"
          title={tipoRecetaSeleccionada.descripcion}
        >
          {tiposRecetas.map((e, key) => {
            return <Dropdown.Item eventKey={key}>{e.descripcion}</Dropdown.Item>;
          })}
        </DropdownButton>
      </div>
      <div className="contenedor-imagen-receta">
        <span className="contenedor-imagen-receta-titulo">{tipoRecetaSeleccionada.descripcion}</span>
        <img src={imgDesayuno} width="100%" alt="" />
      </div>
      <div className="recetas-container">
        {loading && <div>Espera un momento, estamos obteniendo la informacion de los recetas...</div>}
        {error && (
          <div>
            <br />
            <br />
            <br />
            <br />
            Ups!! Ocurrio un error al obtener los recetas, intenta recargar la página nuevamente.
            <br /> Si el problema persiste contacte al administrador del sitio.
          </div>
        )}
        {recetas.map((receta) => {
          return (
            <Link
              className="recetas-componente"
              key={receta.idReceta}
              to={`/recetas/detalle-receta/${receta.idReceta}`}
            >
              <img src={`${process.env.API_URL_CHAPIN}` + receta.url} alt={receta.nombreReceta} />
              <div>
                <div className="recetas-componente-titulo">{receta.nombreReceta}</div>
                <div className="recetas-componente-subtitulo">{receta.productoReceta}</div>
                <div className="recetas-componente-tiempo">
                  <Clock />
                  {`   ${receta.tiempoPreparacion}`}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </>
  );
}

export default Recetas;
